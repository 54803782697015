var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"pr-5 pl-3",attrs:{"color":"#FFFFFF","app":""},scopedSlots:_vm._u([(this.currentRouteName === 'prizeManagement')?{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"color":"#2C1963","grow":"","mobile-breakpoint":"","align-with-title":""},model:{value:(_vm.prizeTabSelected),callback:function ($$v) {_vm.prizeTabSelected=$$v},expression:"prizeTabSelected"}},[_c('v-tabs-slider'),_vm._l((_vm.prizeTab),function(item,i){return _c('v-tab',{key:i,staticClass:"text-capitalize",staticStyle:{"font-size":"15px","font-weight":"600"},on:{"click":function($event){return _vm.selectPrizeTab(item.value)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)]},proxy:true}:(this.currentRouteName === 'emailSettings')?{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"color":"#2C1963","mobile-breakpoint":"","grow":""},model:{value:(_vm.emailSelected),callback:function ($$v) {_vm.emailSelected=$$v},expression:"emailSelected"}},[_c('v-tabs-slider'),_vm._l((_vm.emailTab),function(item,i){return _c('v-tab',{key:i,staticClass:"text-capitalize",staticStyle:{"font-size":"15px","font-weight":"600"},on:{"click":function($event){return _vm.selectEmailTab(item.value)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)]},proxy:true}:null],null,true)},[(_vm.$vuetify.breakpoint.xsOnly ||_vm.$vuetify.breakpoint.mdAndDown)?_c('v-app-bar-nav-icon',{staticClass:"d-flex ",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e(),(_vm.backButtonValidator)?_c('v-btn',{staticClass:"px-0",attrs:{"text":""},on:{"click":function($event){return _vm.routeBack()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1):_vm._e(),_c('v-toolbar-title',{staticClass:"mainToolbar-title"},[_vm._v(_vm._s(_vm.barTitle))]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":""}},on),[_c('v-avatar',{attrs:{"color":"rgba(255, 81, 81, 0.12)","size":"38"}},[_c('span',{staticClass:"user-initials-text"},[_vm._v(_vm._s(_vm.user.initials))])])],1)]}}])},[_c('v-card',[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('v-avatar',{attrs:{"color":"rgba(255, 81, 81, 0.12)"}},[_c('span',{staticClass:"user-initials-text"},[_vm._v(_vm._s(_vm.user.initials))])]),_c('h3',[_vm._v(_vm._s(_vm.user.user_name))]),_c('p',{staticClass:"text-caption mt-1"},[_vm._v(" "+_vm._s(_vm.user.user_email)+" ")]),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"depressed":"","rounded":"","text":""},on:{"click":_vm.logoutClickHandler}},[_vm._v(" Logout ")])],1)])],1)],1)],1),_c('v-navigation-drawer',{staticClass:"d-none d-sm-flex",attrs:{"app":"","dark":"","color":"#2C1963","width":"315px","height":"100%","permanent":"","hide-overlay":""}},[_c('div',{staticClass:"my-10",staticStyle:{"text-align":"-webkit-center"}},[_c('v-img',{attrs:{"max-width":"135","src":require("@/assets/Auth/wizfilogo1.png")}})],1),_c('v-list',{staticClass:"pa-0",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.navLinks),function(item,i){return _c('v-list-item',{key:i,staticClass:"justify-center rounded-0",attrs:{"active-class":"active-class","to":{ name: item.to }},on:{"click":function($event){return _vm.ChangeTab(item.heading)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('div',{staticStyle:{"width":"70%"}},[_c('img',{staticClass:"display-inline",staticStyle:{"max-width":"15px","margin-bottom":"-2px"},attrs:{"src":_vm.getImgUrl(
                    active ? ((item.icon) + "Active.svg") : ((item.icon) + ".svg")
                  )}}),_c('v-list-item-title',{staticClass:"flex-zero display-inline ml-6 font-roboto-slab"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]}}],null,true)})}),1)],1)],1),_c('v-navigation-drawer',{staticClass:"d-flex ",attrs:{"app":"","dark":"","color":"#2C1963","width":"270px","height":"100%","absolute":"","temporary":"","hide-overlay":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"my-10",staticStyle:{"text-align":"-webkit-center"}},[_c('v-img',{attrs:{"width":"100","height":"100","src":require("@/assets/Auth/wizfirLogo.svg")}})],1),_c('v-list',{staticClass:"pa-0",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.navLinks),function(item,i){return _c('v-list-item',{key:i,staticClass:"justify-center",attrs:{"active-class":"active-class","to":{ name: item.to }},on:{"click":function($event){return _vm.ChangeTab(item.heading)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var active = ref.active;
return [_c('div',{staticStyle:{"width":"70%"}},[_c('img',{staticClass:"mb-n1 display-inline",attrs:{"max-width":"19","src":_vm.getImgUrl(
                    active ? ((item.icon) + "Active.svg") : ((item.icon) + ".svg")
                  )}}),_c('v-list-item-title',{staticClass:"flex-zero display-inline ml-5 font-roboto-slab"},[_vm._v(_vm._s(item.title))])],1)]}}],null,true)})}),1)],1)],1),_c('v-main',{staticStyle:{"height":"auto","background":"#f7f7f7"}},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }